/*
 * ⚠ This file is autogenerated ⚠
 */

export const counterVariants = ["Dark", "Light", "White"] as const;

export type CounterVariant = (typeof counterVariants)[number];

export const counterSizes = ["28", "36"] as const;

export type CounterSize = (typeof counterSizes)[number];

export const counterVariantStyles = {
	Dark: {
		backgroundColor: "backgroundDark",
		color: "textLight",
	},
	Light: {
		backgroundColor: "backgroundLightsubtle",
		color: "textDefault",
	},
	White: {
		backgroundColor: "backgroundWhite",
		color: "textDefault",
	},
};
