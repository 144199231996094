/*
 * ⚠ This file is autogenerated ⚠
 */

export interface MotionStyle {
	easingFunctionY2: number;
	easingFunctionY1: number;
	easingFunctionX2: number;
	easingFunctionX1: number;
	easing: string;
	duration: number;
}

export interface Motion {
	moveTortoise: MotionStyle;
	moveElephant: MotionStyle;
	moveRabbit: MotionStyle;
	moveKangaroo: MotionStyle;
	moveCheetah: MotionStyle;
	moveinTortoise: MotionStyle;
	moveinElephant: MotionStyle;
	moveinRabbit: MotionStyle;
	moveinKangaroo: MotionStyle;
	moveinCheetah: MotionStyle;
	moveoutTortoise: MotionStyle;
	moveoutElephant: MotionStyle;
	moveoutRabbit: MotionStyle;
	moveoutKangaroo: MotionStyle;
	moveoutCheetah: MotionStyle;
	fadeinoutTortoise: MotionStyle;
	fadeinoutElephant: MotionStyle;
	fadeinoutRabbit: MotionStyle;
	fadeinoutKangaroo: MotionStyle;
	fadeinoutCheetah: MotionStyle;
}

export const motion: Motion = {
	moveTortoise: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 0.5799999833106995,
		easingFunctionX1: 0.41999998688697815,
		easing: "custom-cubic-bezier",
		duration: 500,
	},
	moveElephant: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 0.5799999833106995,
		easingFunctionX1: 0.41999998688697815,
		easing: "ease-in-out",
		duration: 400,
	},
	moveRabbit: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 0.5799999833106995,
		easingFunctionX1: 0.41999998688697815,
		easing: "ease-in-out",
		duration: 300,
	},
	moveKangaroo: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 0.5799999833106995,
		easingFunctionX1: 0.41999998688697815,
		easing: "ease-in-out",
		duration: 200,
	},
	moveCheetah: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 0.5799999833106995,
		easingFunctionX1: 0.41999998688697815,
		easing: "ease-in-out",
		duration: 100,
	},
	moveinTortoise: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 0.5799999833106995,
		easingFunctionX1: 0,
		easing: "ease-out",
		duration: 500,
	},
	moveinElephant: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 0.5799999833106995,
		easingFunctionX1: 0,
		easing: "ease-out",
		duration: 400,
	},
	moveinRabbit: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 0.5799999833106995,
		easingFunctionX1: 0,
		easing: "ease-out",
		duration: 300,
	},
	moveinKangaroo: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 0.5799999833106995,
		easingFunctionX1: 0,
		easing: "ease-out",
		duration: 200,
	},
	moveinCheetah: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 0.5799999833106995,
		easingFunctionX1: 0,
		easing: "ease-out",
		duration: 100,
	},
	moveoutTortoise: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 1,
		easingFunctionX1: 0.41999998688697815,
		easing: "ease-in",
		duration: 500,
	},
	moveoutElephant: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 1,
		easingFunctionX1: 0.41999998688697815,
		easing: "ease-in",
		duration: 400,
	},
	moveoutRabbit: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 1,
		easingFunctionX1: 0.41999998688697815,
		easing: "ease-in",
		duration: 300,
	},
	moveoutKangaroo: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 1,
		easingFunctionX1: 0.41999998688697815,
		easing: "ease-in",
		duration: 200,
	},
	moveoutCheetah: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 1,
		easingFunctionX1: 0.41999998688697815,
		easing: "ease-in",
		duration: 100,
	},
	fadeinoutTortoise: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 1,
		easingFunctionX1: 0,
		easing: "linear",
		duration: 500,
	},
	fadeinoutElephant: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 1,
		easingFunctionX1: 0,
		easing: "linear",
		duration: 400,
	},
	fadeinoutRabbit: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 1,
		easingFunctionX1: 0,
		easing: "linear",
		duration: 300,
	},
	fadeinoutKangaroo: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 1,
		easingFunctionX1: 0,
		easing: "linear",
		duration: 200,
	},
	fadeinoutCheetah: {
		easingFunctionY2: 1,
		easingFunctionY1: 0,
		easingFunctionX2: 1,
		easingFunctionX1: 0,
		easing: "linear",
		duration: 100,
	},
};
