export const newButtonTypes = [
  "Critical",
  "Informative",
  "Outline",
  "Primary",
  "PrimaryDefault",
  "PrimaryLegacy",
  "Secondary",
  "Success",
  "TextPrimary",
  "TextSecondary",
  "TextSecondaryLight",
  "Warning"
] as const;