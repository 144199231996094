export const buttonVariantStyles = {
  "Critical": {
    "Default": {
      "backgroundColor": "actioncriticalDefault",
      "color": "actioncriticalText",
      "borderRadius": 8
    },
    "Loading": {
      "backgroundColor": "actioncriticalDefault",
      "color": "textLight"
    },
    "Hover": {
      "backgroundColor": "actioncriticalHover",
      "color": "actioncriticalText"
    },
    "Pressed": {
      "backgroundColor": "actioncriticalPressed",
      "color": "actioncriticalText"
    },
    "Disabled": {
      "backgroundColor": "actioncriticalDisabledlight",
      "color": "textDisabled"
    }
  },
  "Informative": {
    "Default": {
      "backgroundColor": "actioninformationDefault",
      "color": "actioninformationText",
      "borderRadius": 8
    },
    "Loading": {
      "backgroundColor": "actioninformationDefault",
      "color": "textLight"
    },
    "Hover": {
      "backgroundColor": "actioninformationHover",
      "color": "actioninformationText"
    },
    "Pressed": {
      "backgroundColor": "actioninformationPressed",
      "color": "actioninformationText"
    },
    "Disabled": {
      "backgroundColor": "actioninformationDisabledlight",
      "color": "textDisabled"
    }
  },
  "PrimaryLegacy": {
    "Default": {
      "backgroundColor": "actionlegacyprimarygreenDefault",
      "color": "actionlegacyprimarygreenText",
      "borderRadius": 8
    },
    "Loading": {
      "backgroundColor": "actionlegacyprimarygreenDefault",
      "color": "textLight"
    },
    "Hover": {
      "backgroundColor": "actionlegacyprimarygreenHover",
      "color": "actionlegacyprimarygreenText"
    },
    "Pressed": {
      "backgroundColor": "actionlegacyprimarygreenPressed",
      "color": "actionlegacyprimarygreenText"
    },
    "Disabled": {
      "backgroundColor": "actionlegacyprimaryDisabledlight",
      "color": "textDisabled"
    }
  },
  "Outline": {
    "Default": {
      "backgroundColor": "backgroundWhite",
      "color": "textDefault",
      "borderRadius": 8
    },
    "Loading": {
      "backgroundColor": "backgroundWhite",
      "color": "textLight"
    },
    "Hover": {
      "backgroundColor": "backgroundWhite",
      "color": "textDefault"
    },
    "Pressed": {
      "backgroundColor": "backgroundWhite",
      "color": "textDefault"
    },
    "Disabled": {
      "backgroundColor": "backgroundWhite",
      "color": "textDisabled"
    }
  },
  "Primary": {
    "Default": {
      "backgroundColor": "actionlegacyprimaryDefault",
      "color": "actionlegacyprimaryText",
      "borderRadius": 8
    },
    "Hover": {
      "backgroundColor": "actionlegacyprimaryHover",
      "color": "actionlegacyprimaryText"
    },
    "Pressed": {
      "backgroundColor": "actionlegacyprimaryPressed",
      "color": "actionlegacyprimaryText"
    },
    "Disabled": {
      "backgroundColor": "actionlegacyprimaryDisabledlight",
      "color": "textDisabled"
    },
    "Loading": {
      "backgroundColor": "actionlegacyprimaryDefault",
      "color": "textLight"
    }
  },
  "PrimaryDefault": {
    "Default": {
      "backgroundColor": "actionprimaryDefault",
      "color": "actionprimaryText",
      "borderRadius": 8
    },
    "Loading": {
      "backgroundColor": "actionprimaryDefault",
      "color": "textLight"
    },
    "Hover": {
      "backgroundColor": "actionprimaryHover",
      "color": "actionprimaryText"
    },
    "Pressed": {
      "backgroundColor": "actionprimaryPressed",
      "color": "actionprimaryText"
    },
    "Disabled": {
      "backgroundColor": "actionprimaryDisabledlight",
      "color": "textDisabled"
    }
  },
  "Success": {
    "Default": {
      "backgroundColor": "actionsuccessDefault",
      "color": "actionsuccessText",
      "borderRadius": 8
    },
    "Loading": {
      "backgroundColor": "actionsuccessDefault",
      "color": "textLight"
    },
    "Hover": {
      "backgroundColor": "actionsuccessHover",
      "color": "actionsuccessText"
    },
    "Pressed": {
      "backgroundColor": "actionsuccessPressed",
      "color": "actionsuccessText"
    },
    "Disabled": {
      "backgroundColor": "actionsuccessDisabledlight",
      "color": "textDisabled"
    }
  },
  "Secondary": {
    "Default": {
      "backgroundColor": "actionlegacysecondaryDefault",
      "color": "actionlegacysecondaryText",
      "borderRadius": 8
    },
    "Loading": {
      "backgroundColor": "actionlegacysecondaryDefault",
      "color": "textLight"
    },
    "Hover": {
      "backgroundColor": "actionlegacysecondaryHover",
      "color": "actionlegacysecondaryText"
    },
    "Pressed": {
      "backgroundColor": "actionlegacysecondaryPressed",
      "color": "actionlegacysecondaryText"
    },
    "Disabled": {
      "backgroundColor": "actionlegacysecondaryDisabledlight",
      "color": "textDisabled"
    }
  },
  "TextSecondary": {
    "Default": {
      "backgroundColor": "backgroundNone",
      "color": "textDefault",
      "borderRadius": 8
    },
    "Loading": {
      "backgroundColor": "backgroundNone",
      "color": "textLight"
    },
    "Hover": {
      "backgroundColor": "backgroundNone",
      "color": "textDefault"
    },
    "Pressed": {
      "backgroundColor": "backgroundNone",
      "color": "textDefault"
    },
    "Disabled": {
      "backgroundColor": "backgroundNone",
      "color": "textDisabled"
    }
  },
  "TextSecondaryLight": {
    "Loading": {
      "backgroundColor": "backgroundNone",
      "color": "textLight"
    },
    "Default": {
      "backgroundColor": "backgroundNone",
      "color": "textLight",
      "borderRadius": 8
    },
    "Hover": {
      "backgroundColor": "backgroundNone",
      "color": "textLight"
    },
    "Pressed": {
      "backgroundColor": "backgroundNone",
      "color": "textLight"
    },
    "Disabled": {
      "backgroundColor": "backgroundNone",
      "color": "textDisabled"
    }
  },
  "TextPrimary": {
    "Loading": {
      "backgroundColor": "backgroundNone",
      "color": "textLight"
    },
    "Default": {
      "backgroundColor": "backgroundNone",
      "color": "textPrimarydark",
      "borderRadius": 8
    },
    "Hover": {
      "backgroundColor": "backgroundNone",
      "color": "textPrimarydark"
    },
    "Pressed": {
      "backgroundColor": "backgroundNone",
      "color": "textPrimarydark"
    },
    "Disabled": {
      "backgroundColor": "backgroundNone",
      "color": "textDisabled"
    }
  },
  "Warning": {
    "Default": {
      "backgroundColor": "actionwarningDefault",
      "color": "actionwarningText",
      "borderRadius": 8
    },
    "Loading": {
      "backgroundColor": "actionwarningDefault",
      "color": "textLight"
    },
    "Hover": {
      "backgroundColor": "actionwarningHover",
      "color": "actionwarningText"
    },
    "Pressed": {
      "backgroundColor": "actionwarningPressed",
      "color": "actionwarningText"
    },
    "Disabled": {
      "backgroundColor": "actionwarningDisabledlight",
      "color": "textDisabled"
    }
  }
} as const;