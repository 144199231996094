const iconButtonSizes = ["Average", "Big", "Huge", "Large"];
const iconButtonTypes = ["Floating", "Heavy", "Primary", "Secondary", "Subtle"];

type IconButtonSizes = (typeof iconButtonSizes)[number];
type IconButtonTypes = (typeof iconButtonTypes)[number];

export {
	iconButtonSizes,
	iconButtonTypes,
	type IconButtonSizes,
	type IconButtonTypes,
};
