/*
 * ⚠ This file is autogenerated ⚠
 */

export type Border = number;

export interface Borders {
	outlinedStrokeWeight: Border;

	activeStrokeWeight: Border;

	focusStrokeWeight: Border;
}

export const borders: Borders = {
	focusStrokeWeight: 4,
	activeStrokeWeight: 2,
	outlinedStrokeWeight: 1,
};
