import { keyframes } from "@emotion/react";
import type { Keyframes } from "@emotion/serialize";

export const fadeIn: Keyframes = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut: Keyframes = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const slideUp: Keyframes = keyframes`
  from {
    transform: translate3d(0, 100%, 0);
  }
`;

export const slideDown: Keyframes = keyframes`
  to {
    transform: translateY(100%);
  }
`;

export const slideLeft: Keyframes = keyframes`
  from {
    transform: translateX(100%);
  }
`;

export const slideRight: Keyframes = keyframes`
  to {
    transform: translateX(100%);
  }
`;

export const spin: Keyframes = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
