/*
 * ⚠ This file is autogenerated ⚠
 */

import type {
	Illustrations,
	Logos,
	Processors,
	RichIcons,
	LegacyIcons,
} from "@loveholidays/design-tokens";

/**
 * Mapping between SVG assets and their natural dimensions.
 *
 * Useful for avoiding layout shifts.
 */
export const viewBoxes: Record<
	| `Illustration/${Illustrations}`
	| `Logo/${Logos}`
	| `Processor/${Processors}`
	| `RichIcon/${RichIcons}`
	| `LegacyIcon/${LegacyIcons}`,
	[number, number]
> = {
	"Illustration/Google/Coloured": [24, 24],
	"Illustration/PayPal/Coloured": [24, 24],
	"Illustration/Trustpilot/Filled": [24, 24],
	"Illustration/Trustpilot/Halved": [24, 25],
	"Illustration/USP/ATOL": [24, 24],
	"Illustration/USP/Confidence": [24, 24],
	"Illustration/USP/Favourites": [24, 24],
	"Illustration/USP/Flexibility": [24, 24],
	"Illustration/USP/FreeCancellation": [24, 24],
	"Illustration/USP/Insurance": [24, 24],
	"Illustration/USP/MobileReviews": [24, 24],
	"Illustration/USP/PayMonthlyDollar": [24, 24],
	"Illustration/USP/PayMonthlyEuro": [24, 24],
	"Illustration/USP/PayMonthlyPound": [24, 24],
	"Illustration/USP/PriceMatch": [24, 24],
	"Illustration/USP/Refunds": [24, 24],
	"Illustration/USP/Standards": [24, 24],
	"Illustration/USP/Support": [24, 24],
	"Illustration/USP/Trustpilot": [24, 24],
	"Illustration/USP/Trustpilot2": [24, 24],
	"LegacyIcon/AmusementPark": [24, 24],
	"LegacyIcon/BeachBall": [24, 24],
	"LegacyIcon/Camera": [24, 24],
	"LegacyIcon/Candles": [24, 24],
	"LegacyIcon/Canoe": [24, 24],
	"LegacyIcon/Castle": [24, 24],
	"LegacyIcon/Children": [29, 24],
	"LegacyIcon/CityAttractions": [24, 24],
	"LegacyIcon/Cocktail": [24, 24],
	"LegacyIcon/CoffeeCup": [24, 24],
	"LegacyIcon/Compass": [24, 24],
	"LegacyIcon/Cutlery": [20, 24],
	"LegacyIcon/DiningTable": [24, 24],
	"LegacyIcon/DoubleHeart": [28, 24],
	"LegacyIcon/Drink": [24, 24],
	"LegacyIcon/Forest": [24, 24],
	"LegacyIcon/Fountain": [24, 24],
	"LegacyIcon/Heart": [24, 24],
	"LegacyIcon/HistoricSite": [24, 24],
	"LegacyIcon/Kite": [24, 24],
	"LegacyIcon/Leisure": [30, 24],
	"LegacyIcon/MapMarker": [24, 24],
	"LegacyIcon/Market": [24, 24],
	"LegacyIcon/Mountains": [24, 24],
	"LegacyIcon/Museum": [24, 24],
	"LegacyIcon/ShoppingBag": [24, 24],
	"LegacyIcon/Ski": [24, 24],
	"LegacyIcon/Sunbathe": [24, 24],
	"LegacyIcon/SurfVan": [24, 24],
	"LegacyIcon/Teddy": [24, 24],
	"LegacyIcon/Tickets": [24, 24],
	"LegacyIcon/Time": [21, 24],
	"LegacyIcon/WaterPark": [22, 24],
	"LegacyIcon/Wine": [24, 24],
	"Logo/ATOL/Dark": [60, 61],
	"Logo/ATOL/Light": [60, 61],
	"Logo/Allianz/Colour": [124, 32],
	"Logo/Allianz/Dark": [124, 32],
	"Logo/Allianz/Light": [124, 32],
	"Logo/AllianzPartners/Colour": [195, 32],
	"Logo/AllianzPartners/Dark": [195, 32],
	"Logo/AllianzPartners/Light": [195, 32],
	"Logo/AppStore/Dark_de-DE": [158, 53],
	"Logo/AppStore/Dark_en-GB": [158, 53],
	"Logo/AppStore/Dark_en-IE": [158, 53],
	"Logo/BayerLeverkusen/Colour": [200, 100],
	"Logo/DRV/Colour": [80, 45],
	"Logo/DRV/Dark": [80, 45],
	"Logo/DRV/Light": [80, 45],
	"Logo/FocusMoney/Colour": [57, 100],
	"Logo/GooglePlay/Dark_de-DE": [158, 47],
	"Logo/GooglePlay/Dark_en-GB": [158, 47],
	"Logo/GooglePlay/Dark_en-IE": [158, 47],
	"Logo/HolidayCheck/Colour": [141, 32],
	"Logo/IATA/LargeDark": [80, 52],
	"Logo/IATA/LargeLight": [80, 52],
	"Logo/Ideal/Colour": [35, 32],
	"Logo/Loveholidays/LargeColouredDark": [100, 33],
	"Logo/Loveholidays/LargeColouredDark_New": [160, 32],
	"Logo/Loveholidays/LargeColouredLight": [100, 33],
	"Logo/Loveholidays/LargeColouredLight_New": [160, 32],
	"Logo/Loveholidays/LargeDark": [100, 33],
	"Logo/Loveholidays/LargeDark_New": [160, 32],
	"Logo/Loveholidays/LargeLight": [100, 33],
	"Logo/Loveholidays/LargeLight_New": [160, 32],
	"Logo/Lovevacations/LargeColouredDark": [111, 33],
	"Logo/Lovevacations/LargeColouredLight": [111, 33],
	"Logo/Lovevacations/LargeDark": [111, 33],
	"Logo/Lovevacations/LargeLight": [111, 33],
	"Logo/PayPal/Dark": [96, 32],
	"Logo/PayPal/Light": [96, 32],
	"Logo/TUV/Colour": [200, 106],
	"Logo/TravelAware/Colour": [60, 56],
	"Logo/TravelAware/Dark": [60, 56],
	"Logo/TravelAware/Light": [60, 56],
	"Logo/TrustPilot/LargeColoured": [100, 25],
	"Logo/VIR/Colour": [80, 54],
	"Logo/VIR/Dark": [80, 54],
	"Logo/VIR/Light": [80, 54],
	"Logo/VerifiedRyanair/Colour": [119, 32],
	"Logo/weLoveholidays/LargeColouredDark_New": [192, 32],
	"Logo/weLoveholidays/LargeColouredLight_New": [192, 32],
	"Logo/weLoveholidays/LargeDark_New": [192, 32],
	"Logo/weLoveholidays/LargeLight_New": [192, 32],
	"Processor/Amex": [70, 48],
	"Processor/ApplePay": [70, 48],
	"Processor/DirectDebitDE": [70, 48],
	"Processor/GooglePay": [70, 48],
	"Processor/InvoiceDE": [70, 48],
	"Processor/Mastercard": [70, 48],
	"Processor/PayPal": [70, 48],
	"Processor/Sofort": [70, 48],
	"Processor/Visa": [70, 48],
	"Processor/klarna": [70, 48],
	"RichIcon/Bookings": [48, 48],
	"RichIcon/Favourites": [48, 48],
	"RichIcon/PersonalDetails": [48, 48],
	"RichIcon/Prices": [48, 48],
	"RichIcon/RoomOptions": [48, 48],
	"RichIcon/SettingsAndSubscriptions": [48, 48],
	"RichIcon/Transfers": [48, 48],
	"RichIcon/Vouchers": [48, 48],
};
