/*
 * ⚠ This file is autogenerated ⚠
 */

export type Effect = any;

export interface Effects {
	elevationElevated: Effect;
	elevationHover: Effect;
	elevationFixed: Effect;
	elevationNone: Effect;
	elevationFloating: Effect;
}

export const effects: Effects = {
	elevationElevated: {
		type: "dropShadow",
		radius: "10px",
		color: "rgba(206, 207, 224, 0.25)",
		offsetX: "0",
		offsetY: "6px",
		spread: "0",
	},
	elevationHover: {
		type: "dropShadow",
		radius: "15px",
		color: "rgba(206, 207, 224, 0.25)",
		offsetX: "0",
		offsetY: "6px",
		spread: "5px",
	},
	elevationFixed: {
		type: "dropShadow",
		radius: "32px",
		color: "rgba(119, 122, 161, 0.2)",
		offsetX: "0",
		offsetY: "0",
		spread: "0",
	},
	elevationNone: {
		type: "dropShadow",
		radius: "0",
		color: "rgba(0, 0, 0, 0)",
		offsetX: "0",
		offsetY: "0",
		spread: "0",
	},
	elevationFloating: {
		type: "dropShadow",
		radius: "20px",
		color: "rgba(69, 70, 90, 0.3)",
		offsetX: "0",
		offsetY: "0",
		spread: "0",
	},
};
