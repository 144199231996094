import { svgAssets } from "./generated/svgAssets.js";

export const iconList = svgAssets.Icon;
export const logoList = svgAssets.Logo;
export const illustrationList = svgAssets.Illustration;
export const processorsList = svgAssets.Processor;
export const richIconList = svgAssets.RichIcon;
export const legacyIconList = svgAssets.LegacyIcon;

export type Icons = (typeof iconList)[number];
export type Logos = (typeof logoList)[number];
export type Processors = (typeof processorsList)[number];
export type Illustrations = (typeof illustrationList)[number];
export type RichIcons = (typeof richIconList)[number];
export type LegacyIcons = (typeof legacyIconList)[number];
