export const incrementSizes = {
  "12": 12,
  "16": 16,
  "20": 20,
  "24": 24,
  "28": 28,
  "36": 36,
  "40": 40,
  "48": 48,
  "64": 64,
  "100": 100,
  "200": 200,
  "420": 420,
  "sidebarwidth": 300,
  "formsmaxwidth": 620,
  "copymaxwidth": 768,
  "contentmaxwidth": 960,
  "fullcontentmaxwidth": 1280
} as const;