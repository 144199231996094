export type ZIndex = number;

export type ZIndices = {
	modal: ZIndex;
	tooltip: ZIndex;
	stickyContent: ZIndex;
	toast: ZIndex;
	errorPage: ZIndex;
};

export const zIndices: ZIndices = {
	stickyContent: 95,
	modal: 100,
	tooltip: 101,
	toast: 102,
	errorPage: 250,
};
