export const colors = {
  /** @deprecated replace with:
  ActionCritical/Default */
  "actionattentionDefault": "#d43b3b",
  /** @deprecated replace with:
  ActionCritical/Disabled */
  "actionattentionDisabledlight": "#f2f2f8",
  /** @deprecated replace with:
  ActionCritical/Hover */
  "actionattentionHover": "#ff5c5c",
  /** @deprecated replace with:
  ActionCritical/Pressed */
  "actionattentionPressed": "#d43b3b",
  "actioncriticalDefault": "#e33d2d",
  "actioncriticalDisabledlight": "#e6e8e9",
  "actioncriticalHover": "#cc3728",
  "actioncriticalPressed": "#b63124",
  "actioncriticalText": "#fff",
  "actioninformationDefault": "#024e94",
  "actioninformationDisabledlight": "#e6e8e9",
  "actioninformationHover": "#024685",
  "actioninformationText": "#fff",
  "actionlegacyprimaryDefault": "#ffbc2b",
  "actionlegacyprimaryDisabledlight": "#e6e8e9",
  "actionlegacyprimarygreenDefault": "#4abb10",
  "actionlegacyprimarygreenText": "#fff",
  "actionlegacyprimaryHover": "#e5aa27",
  "actionlegacyprimaryPressed": "#cc9722",
  "actionlegacyprimaryText": "#071a26",
  "actionlegacysecondaryDefault": "#f2efe8",
  "actionlegacysecondaryDisabledlight": "#e6e8e9",
  "actionlegacysecondaryHover": "#dad7d1",
  "actionlegacysecondaryPressed": "#c2bfba",
  "actionlegacysecondaryText": "#071a26",
  "actionprimaryDefault": "#0374da",
  "actionprimaryDisabledlight": "#e6e8e9",
  "actionprimaryHover": "#0268bd",
  "actionprimaryPressed": "#025da8",
  "actionprimaryText": "#fff",
  "actionsecondaryDefault": "#e6e8e9",
  "actionsecondaryDisabledlight": "#e6e8e9",
  "actionsecondaryHover": "#cdd1d4",
  "actionsecondaryPressed": "#b5babe",
  "actionsecondaryText": "#071a26",
  "actionsuccessDefault": "#037a44",
  "actionsuccessDisabledlight": "#e6e8e9",
  "actionsuccessHover": "#036e3d",
  "actionsuccessPressed": "#026236",
  "actionsuccessText": "#fff",
  /** @deprecated replace with:
  ActionSuccess/Default */
  "actionvalidationDefault": "#00866f",
  /** @deprecated replace with:
  ActionSuccess/Disabled */
  "actionvalidationDisabledlight": "#f2f2f8",
  /** @deprecated replace with:
  ActionSuccess/Hover */
  "actionvalidationHover": "#00a980",
  /** @deprecated replace with:
  ActionSuccess/Pressed */
  "actionvalidationPressed": "#00866f",
  "actionwarningDefault": "#bd5b00",
  "actionwarningDisabledlight": "#e6e8e9",
  "actionwarningHover": "#aa5200",
  "actionwarningPressed": "#974900",
  "actionwarningText": "#fff",
  /** @deprecated replace with:
  Background/PrimaryLight */
  "backgroundAddtag": "#e4f1ff",
  /** @deprecate replace with Background/Critical */
  "backgroundAttention": "#ff5c5c",
  /** @deprecated replace with:
  Background/CriticalLight */
  "backgroundAttentionlight": "#ffeded",
  /** @deprecated replace with:
  Background/CriticalMedium */
  "backgroundAttentionmedium": "#ffb2b2",
  "backgroundBlack": "#000",
  "backgroundCritical": "#ee8b81",
  "backgroundCriticaldark": "#e33d2d",
  "backgroundCriticallight": "#fef5f5",
  "backgroundCriticalmedium": "#f9d8d5",
  "backgroundDark": "#071a26",
  "backgroundDarkneutral": "#9ca3a8",
  "backgroundDarksubtle": "#6a767d",
  /** Unchanged */
  "backgroundDiscount": "#ff5c5c",
  /** Unchanged */
  "backgroundDiscountdark": "#d43b3b",
  /** Unchanged */
  "backgroundDiscountlight": "#ffeded",
  /** Unchanged */
  "backgroundDiscountmedium": "#ffb2b2",
  "backgroundInformation": "#6795bf",
  "backgroundInformationdark": "#024e94",
  "backgroundInformationlight": "#f2f6fa",
  "backgroundInformationmedium": "#ccdcea",
  "backgroundLight": "#fcfcfa",
  "backgroundLightneutral": "#f2efe8",
  "backgroundLightsubtle": "#f7f5f1",
  /** @deprecated */
  "backgroundMove": "#e4f5ef",
  "backgroundNone": "rgba(255,255,255,0)",
  "backgroundPrimary": "#0374da",
  "backgroundPrimarylight": "#f2f8fd",
  "backgroundPrimarymedium": "#cce3f6",
  /** @deprecated replace with:
  Background/LightSubtle */
  "backgroundRemovetag": "#ebe5f9",
  "backgroundSuccess": "#68af8f",
  "backgroundSuccessdark": "#037a44",
  "backgroundSuccesslight": "#f2f8f6",
  "backgroundSuccessmedium": "#cde4da",
  /** @deprecated replace with:
  Background/Success */
  "backgroundValidation": "#00a980",
  /** @deprecated replace with:
  Background/SuccessLight */
  "backgroundValidationlight": "#e4f5ef",
  /** @deprecated replace with:
  Background/SuccessMedium */
  "backgroundValidationmedium": "#88e3d3",
  "backgroundWarning": "#d79d66",
  "backgroundWarningdark": "#bd5b00",
  "backgroundWarninglight": "#fcf7f2",
  "backgroundWarningmedium": "#f2decc",
  "backgroundWhite": "#fff",
  /** @deprecated replace with:
  Base/Critical */
  "baseAttention": "#ff5c5c",
  "baseCritical": "#e33d2d",
  "baseDark": "#071a26",
  "baseInformation": "#024e94",
  "baseInteraction": "#071a26",
  "baseLight": "#f2efe8",
  "basePrimary": "#0374da",
  "baseSecondary": "#cde3f8",
  "baseSuccess": "#037a44",
  /** @deprecated replace with:
  Base/Success */
  "baseValidation": "#00a980",
  "baseYellow": "#ffbc2b",
  "baseYellowlight": "#fdeac4",
  "focusDefault": "#68ace9",
  /** @deprecated replace with:
  Icon/Critical */
  "iconAttentionlarge": "#ff5c5c",
  /** @deprecated replace with:
  Icon/CriticalDark */
  "iconAttentionsmall": "#d43b3b",
  "iconCritical": "#ee8b81",
  "iconCriticaldark": "#e33d2d",
  "iconDefault": "#14142b",
  "iconDimmedheavy": "#394851",
  "iconDimmedmedium": "#6a767d",
  "iconDisabled": "#cdd1d4",
  "iconDisabledlight": "#e6e8e9",
  "iconDiscount": "#ff5c5c",
  "iconDiscountdark": "#d43b3b",
  "iconGooglereview": "#f4b400",
  "iconHovered": "#061722",
  "iconInformation": "#6795bf",
  "iconInformationdark": "#024e94",
  /** @deprecated replace with:
  Icon/Warning */
  "iconInformationlarge": "#ffcc80",
  /** @deprecated replace with:
  Icon/WarningDark */
  "iconInformationsmall": "#e17a00",
  "iconLight": "#fff",
  "iconPressed": "#071a26",
  "iconPrimary": "#0374da",
  "iconPrimarydark": "#0268bd",
  /** @deprecated replace with: Icon/PrimaryDark */
  "iconPrimarysmall": "#0a70a3",
  "iconSuccess": "#68af8f",
  "iconSuccessdark": "#037a44",
  "iconTripadvisor": "#00aa6c",
  "iconTrustpilot0star": "#d9dbe9",
  "iconTrustpilot1star": "#ff3722",
  "iconTrustpilot2star": "#ff8622",
  "iconTrustpilot3star": "#ffce00",
  "iconTrustpilot4star": "#73cf11",
  "iconTrustpilot5star": "#00b67a",
  /** @deprecated replace with:
  Icon/Success */
  "iconValidationlarge": "#00a980",
  /** @deprecated replace with:
  Icon/SuccessDark */
  "iconValidationsmall": "#00866f",
  "iconWarning": "#d79d66",
  "iconWarningdark": "#bd5b00",
  "interactiveDefault": "#6a767d",
  "interactiveDisabledlight": "#e6e8e9",
  "interactiveHover": "#394851",
  "interactiveSelected": "#071a26",
  /** @deprecated */
  "opacityLighten4": "rgba(255,255,255,0.5)",
  /** @deprecated */
  "overlayHover": "rgba(219,223,255,0.14)",
  "strokeCritical": "#ee8b81",
  "strokeCriticaldark": "#e33d2d",
  "strokeCriticallight": "#fef5f5",
  "strokeCriticalmedium": "#f9d8d5",
  "strokeDark": "#14142b",
  "strokeDarkneutral": "#9ca3a8",
  "strokeDisabledlight": "#cdd1d4",
  "strokeDiscount": "#ff5c5c",
  "strokeDiscountdark": "#d43b3b",
  "strokeDiscountlight": "#ffeded",
  "strokeDiscountmedium": "#ffb2b2",
  "strokeFocus": "#68ace9",
  "strokeInformation": "#6795bf",
  "strokeInformationdark": "#024e94",
  "strokeInformationlight": "#f2f6fa",
  "strokeInformationmedium": "#ccdcea",
  "strokeLight": "#f3f4f4",
  "strokeLightneutral": "#f2efe8",
  "strokeLightsubtle": "#f7f5f1",
  "strokeNone": "rgba(255,255,255,0)",
  "strokePrimary": "#0374da",
  "strokePrimarydark": "#0268bd",
  "strokeSuccess": "#68af8f",
  "strokeSuccessdark": "#037a44",
  "strokeSuccesslight": "#f2f8f6",
  "strokeSuccessmedium": "#cde4da",
  "strokeWarning": "#d79d66",
  "strokeWarningdark": "#bd5b00",
  "strokeWarningmedium": "#f2decc",
  "strokeWhite": "#fff",
  "tagprimaryDefault": "#c8e4ff",
  "tagprimaryDisabledlight": "#e6e8e9",
  "tagprimaryHover": "#a3d1ff",
  "tagprimaryPressed": "#6db6ff",
  "tagprimaryText": "#071a26",
  /** @deprecated replace with:
  Text/Critical */
  "textAttentionlarge": "#ff5c5c",
  /** @deprecated replace with:
  Text/CriticalDark */
  "textAttentionsmall": "#d43b3b",
  "textCritical": "#ee8b81",
  "textCriticaldark": "#cc3728",
  /** Alias */
  "textDefault": "#071a26",
  "textDimmedheavy": "#394851",
  "textDimmedmedium": "#6a767d",
  "textDisabled": "#9ca3a8",
  "textDisabledlight": "#e6e8e9",
  "textDiscount": "#ff5c5c",
  "textDiscountdark": "#d43b3b",
  "textInformation": "#6795bf",
  "textInformationdark": "#024e94",
  /** @deprecated replace with:
  Text/Warning */
  "textInformationlarge": "#ffcc80",
  /** @deprecated replace with:
  Text/WarningDark */
  "textInformationsmall": "#e17a00",
  "textLegacy": "#ffbc2b",
  "textLegacydark": "#e5aa27",
  "textLight": "#fff",
  "textPrimary": "#0374da",
  "textPrimarydark": "#0268bd",
  /** @deprecated replace with:
  Text/PrimaryDark */
  "textPrimarysmall": "#0a70a3",
  "textSuccess": "#68af8f",
  "textSuccessdark": "#037a44",
  /** @deprecated replace with:
  Text/Success */
  "textValidationlarge": "#00a980",
  /** @deprecated replace with:
  Text/SuccessDark */
  "textValidationsmall": "#00866f",
  "textWarningdark": "#bd5b00"
} as const;