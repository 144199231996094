export const spacerSizes = {
  "5xs": 2,
  "4xs": 4,
  "3xs": 8,
  "2xs": 12,
  "xs": 16,
  "s": 20,
  "l": 24,
  "xl": 32,
  "2xl": 40,
  "3xl": 56,
  "4xl": 80,
  "5xl": 160
} as const;