export const svgAssets = {
  "Icon": [
    "Actions/AdjustAdd",
    "Actions/AdjustAddCircle",
    "Actions/AdjustRemove",
    "Actions/AdjustRemoveCircle",
    "Actions/Alert",
    "Actions/Attach",
    "Actions/Call",
    "Actions/Cancel",
    "Actions/Cloud",
    "Actions/Collapse",
    "Actions/Confirm",
    "Actions/Copy",
    "Actions/Delete",
    "Actions/DeleteFilled",
    "Actions/Download",
    "Actions/Edit",
    "Actions/Email",
    "Actions/Enlarge",
    "Actions/Expand",
    "Actions/FontSize",
    "Actions/FullScreen",
    "Actions/Headset",
    "Actions/Login",
    "Actions/Logout",
    "Actions/Microphone",
    "Actions/MoveNext",
    "Actions/MoveNext2",
    "Actions/MovePrevious",
    "Actions/MovePrevious2",
    "Actions/Offline",
    "Actions/Pay",
    "Actions/PlusMinus",
    "Actions/Print",
    "Actions/Refresh",
    "Actions/Remove",
    "Actions/ScreenReader",
    "Actions/Search",
    "Actions/SearchFilled",
    "Actions/Select",
    "Actions/Send",
    "Actions/Share",
    "Actions/ShareV2",
    "Actions/Sort",
    "Actions/Spinner",
    "Actions/Upload",
    "Actions/VolumeDown",
    "Actions/VolumeOff",
    "Actions/VolumeUp",
    "Brands/Amex",
    "Brands/Apple",
    "Brands/AppleFilled",
    "Brands/Discover",
    "Brands/Facebook",
    "Brands/FacebookFilled",
    "Brands/Google",
    "Brands/GoogleFilled",
    "Brands/GooglePlay",
    "Brands/GooglePlayFilled",
    "Brands/Instagram",
    "Brands/InstagramFilled",
    "Brands/LinkedIn",
    "Brands/LinkedinFilled",
    "Brands/Loveholidays",
    "Brands/Mastercard",
    "Brands/MastercardFilled",
    "Brands/Paypal",
    "Brands/PaypalFilled",
    "Brands/Pinterest",
    "Brands/PinterestFilled",
    "Brands/Tiktok",
    "Brands/TiktokFilled",
    "Brands/TripAdvisor",
    "Brands/Trustpilot",
    "Brands/Visa",
    "Brands/X",
    "Brands/XFilled",
    "Brands/Youtube",
    "Brands/YoutubeFilled",
    "Content/Accessibility",
    "Content/Activities",
    "Content/AddPassenger",
    "Content/AdultInfantSeat",
    "Content/AllInclusive",
    "Content/Amusement",
    "Content/Baby",
    "Content/Budget",
    "Content/Child",
    "Content/Customer",
    "Content/CustomerCouple",
    "Content/CustomerFamily",
    "Content/CustomerGroup",
    "Content/DiningTable",
    "Content/Document",
    "Content/EditPassenger",
    "Content/Entertainment",
    "Content/Facilities",
    "Content/FacilitiesPool",
    "Content/FacilitiesWiFi",
    "Content/FlightDetails",
    "Content/FlightLayover",
    "Content/FlightSeat",
    "Content/Food",
    "Content/FoodBoard",
    "Content/FoodBreakfast",
    "Content/FoodDrinks",
    "Content/FreeCancellation",
    "Content/HolidayBooking",
    "Content/HolidayBookingFilled",
    "Content/InfoApp",
    "Content/InfoCookies",
    "Content/InfoMission",
    "Content/InfoPrivacy",
    "Content/InputDate",
    "Content/InputDate2",
    "Content/InputEmail",
    "Content/InputPhone",
    "Content/InputTime",
    "Content/Insights",
    "Content/Insights2",
    "Content/Language",
    "Content/LastMinuteDeals",
    "Content/Luxury",
    "Content/MedKit",
    "Content/MediaBlog",
    "Content/MediaImage",
    "Content/MediaPhoto",
    "Content/MediaTag",
    "Content/MediaVideo",
    "Content/MeetAndGreet",
    "Content/Moon",
    "Content/Msg",
    "Content/MsgChat",
    "Content/MsgSupport",
    "Content/OffParking",
    "Content/OutstandingBalance",
    "Content/ParkingAirport",
    "Content/PayCVV",
    "Content/PayCard",
    "Content/PayInvoiceEU",
    "Content/PayInvoiceUK",
    "Content/Place",
    "Content/PlaceAirport",
    "Content/PlaceAnywhere",
    "Content/PlaceArrival",
    "Content/PlaceBeach",
    "Content/PlaceDirection",
    "Content/PlaceHome",
    "Content/PlaceHomeFilled",
    "Content/PlaceHotel",
    "Content/PlaceMap",
    "Content/PlaceNavigation",
    "Content/PlaceNearby",
    "Content/PlaceResort",
    "Content/PlaceReturn",
    "Content/PlaceTemperature",
    "Content/PlaceTown",
    "Content/PriorityBoard",
    "Content/PromoCode",
    "Content/RemovePassenger",
    "Content/Reviews",
    "Content/Room",
    "Content/RoomSingle",
    "Content/RoomTwin",
    "Content/SelfCatering",
    "Content/SkiingHoliday",
    "Content/Spa",
    "Content/Sports",
    "Content/Sun",
    "Content/Sunbathe",
    "Content/SunsetViews",
    "Content/ThingsToDo",
    "Content/TrafficLight",
    "Content/Transfer",
    "Content/TransportCab",
    "Content/TransportCar",
    "Content/TransportExpress",
    "Content/TransportMiniBus",
    "Content/TransportNoParking",
    "Content/TransportParking",
    "Content/TransportShuttle",
    "Content/TravelBooking",
    "Content/TravelCarryOn",
    "Content/TravelExtras",
    "Content/TravelFlight",
    "Content/TravelInsurance",
    "Content/TravelLuggage",
    "Content/TravelSuitcase",
    "Content/TrendingDown",
    "Content/TrendingFlat",
    "Content/TrendingUp",
    "Content/USPATOL",
    "Content/USPConfidence",
    "Content/USPFavourites",
    "Content/USPFlexibility",
    "Content/USPInsurance",
    "Content/USPMobileReviews",
    "Content/USPNewsletter",
    "Content/USPPayMonthlyDollar",
    "Content/USPPayMonthlyEuro",
    "Content/USPPayMonthlyPound",
    "Content/USPPriceMatch",
    "Content/USPRefunds",
    "Content/USPStandards",
    "Content/USPSupport",
    "Content/USPTrustPilot",
    "Markers/Alert",
    "Markers/AlertBold",
    "Markers/AlertFilled",
    "Markers/Block",
    "Markers/BlockFilled",
    "Markers/Bullet",
    "Markers/Confirmation",
    "Markers/ConfirmationBold",
    "Markers/ConfirmationFilled",
    "Markers/CurrencyEUR",
    "Markers/CurrencyGBP",
    "Markers/CurrencyUSD",
    "Markers/Disruption",
    "Markers/DisruptionFilled",
    "Markers/Error",
    "Markers/ErrorBold",
    "Markers/ErrorFilled",
    "Markers/FlightsIn",
    "Markers/FlightsInFilled",
    "Markers/FlightsOut",
    "Markers/FlightsOutFilled",
    "Markers/GoogleStarRating",
    "Markers/GoogleStarRatingFilled",
    "Markers/GoogleStarRatingHalf",
    "Markers/HolidayCheckRecommendation",
    "Markers/HolidayCheckSun",
    "Markers/Information",
    "Markers/InformationFilled",
    "Markers/Location",
    "Markers/LocationFilled",
    "Markers/Safety",
    "Markers/SafetyAlert",
    "Markers/SafetyAlertFilled",
    "Markers/SafetyCovid",
    "Markers/SafetyCovidFilled",
    "Markers/SafetyFilled",
    "Markers/StarEmpty",
    "Markers/StarFilled",
    "Markers/StarHalf",
    "Markers/ThumbsDown",
    "Markers/ThumbsDownFilled",
    "Markers/ThumbsUp",
    "Markers/ThumbsUpFilled",
    "Markers/Tooltip",
    "Markers/TooltipFilled",
    "Markers/TripadvisorEmpty",
    "Markers/TripadvisorFilled",
    "Markers/TripadvisorHalf",
    "Navigation/ArrowBack",
    "Navigation/ArrowExternal",
    "Navigation/ArrowForward",
    "Navigation/ArrowList",
    "Navigation/ArrowListFilled",
    "Navigation/Close",
    "Navigation/ExternalLink",
    "Navigation/Grid",
    "Navigation/Menu",
    "Navigation/MenuOpen",
    "Navigation/More",
    "Navigation/MoreVertical",
    "Navigation/Preferences",
    "Navigation/Read",
    "Navigation/Settings",
    "Sentimental/Bad",
    "Sentimental/Delighted",
    "Sentimental/Disappointed",
    "Sentimental/Dissatisfied",
    "Sentimental/Good",
    "Sentimental/Neutral",
    "Sentimental/Satisfied",
    "Sentimental/VeryDissatisfied",
    "Sentimental/VerySatisfied",
    "Toggles/BookmarkOff",
    "Toggles/BookmarkOn",
    "Toggles/CheckOff",
    "Toggles/CheckOn",
    "Toggles/ExpandOff",
    "Toggles/ExpandOff2",
    "Toggles/ExpandOn",
    "Toggles/ExpandOn2",
    "Toggles/FavoriteOff",
    "Toggles/FavoriteOn",
    "Toggles/LockOff",
    "Toggles/LockOn",
    "Toggles/RadioOff",
    "Toggles/RadioOn",
    "Toggles/ShowOff",
    "Toggles/ShowOn"
  ],
  "Logo": [
    "ATOL/Dark",
    "ATOL/Light",
    "Allianz/Colour",
    "Allianz/Dark",
    "Allianz/Light",
    "AllianzPartners/Colour",
    "AllianzPartners/Dark",
    "AllianzPartners/Light",
    "AppStore/Dark_de-DE",
    "AppStore/Dark_en-GB",
    "AppStore/Dark_en-IE",
    "BayerLeverkusen/Colour",
    "DRV/Colour",
    "DRV/Dark",
    "DRV/Light",
    "FocusMoney/Colour",
    "GooglePlay/Dark_de-DE",
    "GooglePlay/Dark_en-GB",
    "GooglePlay/Dark_en-IE",
    "HolidayCheck/Colour",
    "IATA/LargeDark",
    "IATA/LargeLight",
    "Ideal/Colour",
    "Loveholidays/LargeColouredDark",
    "Loveholidays/LargeColouredDark_New",
    "Loveholidays/LargeColouredLight",
    "Loveholidays/LargeColouredLight_New",
    "Loveholidays/LargeDark",
    "Loveholidays/LargeDark_New",
    "Loveholidays/LargeLight",
    "Loveholidays/LargeLight_New",
    "Lovevacations/LargeColouredDark",
    "Lovevacations/LargeColouredLight",
    "Lovevacations/LargeDark",
    "Lovevacations/LargeLight",
    "PayPal/Dark",
    "PayPal/Light",
    "TUV/Colour",
    "TravelAware/Colour",
    "TravelAware/Dark",
    "TravelAware/Light",
    "TrustPilot/LargeColoured",
    "VIR/Colour",
    "VIR/Dark",
    "VIR/Light",
    "VerifiedRyanair/Colour",
    "weLoveholidays/LargeColouredDark_New",
    "weLoveholidays/LargeColouredLight_New",
    "weLoveholidays/LargeDark_New",
    "weLoveholidays/LargeLight_New"
  ],
  "Illustration": [
    "Google/Coloured",
    "PayPal/Coloured",
    "Trustpilot/Filled",
    "Trustpilot/Halved",
    "USP/ATOL",
    "USP/Confidence",
    "USP/Favourites",
    "USP/Flexibility",
    "USP/FreeCancellation",
    "USP/Insurance",
    "USP/MobileReviews",
    "USP/PayMonthlyDollar",
    "USP/PayMonthlyEuro",
    "USP/PayMonthlyPound",
    "USP/PriceMatch",
    "USP/Refunds",
    "USP/Standards",
    "USP/Support",
    "USP/Trustpilot",
    "USP/Trustpilot2"
  ],
  "Processor": [
    "Amex",
    "ApplePay",
    "DirectDebitDE",
    "GooglePay",
    "InvoiceDE",
    "Mastercard",
    "PayPal",
    "Sofort",
    "Visa",
    "klarna"
  ],
  "RichIcon": [
    "Bookings",
    "Favourites",
    "PersonalDetails",
    "Prices",
    "RoomOptions",
    "SettingsAndSubscriptions",
    "Transfers",
    "Vouchers"
  ],
  "LegacyIcon": [
    "AmusementPark",
    "BeachBall",
    "Camera",
    "Candles",
    "Canoe",
    "Castle",
    "Children",
    "CityAttractions",
    "Cocktail",
    "CoffeeCup",
    "Compass",
    "Cutlery",
    "DiningTable",
    "DoubleHeart",
    "Drink",
    "Forest",
    "Fountain",
    "Heart",
    "HistoricSite",
    "Kite",
    "Leisure",
    "MapMarker",
    "Market",
    "Mountains",
    "Museum",
    "ShoppingBag",
    "Ski",
    "Sunbathe",
    "SurfVan",
    "Teddy",
    "Tickets",
    "Time",
    "WaterPark",
    "Wine"
  ]
} as const;