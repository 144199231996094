export const breakpointSizes = {
  "brickMargin": 8,
  "brickGutter": 8,
  "tabletGutter": 16,
  "mobileMargin": 16,
  "mobileGutter": 16,
  "desktopGutter": 24,
  "laptopGutter": 24,
  "laptopMargin": 40,
  "tabletMargin": 40,
  "desktopMargin": 160
} as const;