export const loveholidaysOldColors = {
	actionlegacyprimarygreenText: "#fcfcfc",
	actionlegacyprimarygreenPressed: "#4abb10",
	actionlegacyprimarygreenDisabledlight: "#f2f2f8",
	actionlegacyprimarygreenHover: "#5ec031",
	actionlegacyprimarygreenDefault: "#4abb10",
	opacityLighten4: "rgba(255, 255, 255, 0.5)",
	modalOverlay: "rgba(0, 0, 0, 0.7)",
	lighten4: "rgba(255, 255, 255, 0.5)",
	overlayHover: "rgba(219, 223, 255, 0.14)",
	actionattentionPressed: "#d43b3b",
	actionattentionDisabledlight: "#f2f2f8",
	actionattentionHover: "#ff5c5c",
	actionattentionDefault: "#d43b3b",
	actionvalidationPressed: "#00866f",
	actionvalidationDisabledlight: "#f2f2f8",
	actionvalidationHover: "#00a980",
	actionvalidationDefault: "#00866f",
	tagprimaryText: "#fcfcfc",
	tagprimaryPressed: "#345ae4",
	tagprimaryDisabledlight: "#f2f2f8",
	tagprimaryHover: "#345ae4",
	tagprimaryDefault: "#4b73ff",
	actioninformationText: "#fcfcfc",
	actioninformationPressed: "#0a70a3",
	actioninformationDisabledlight: "#f2f2f8",
	actioninformationHover: "#0a70a3",
	actioninformationDefault: "#0a70a3",
	actionwarningText: "#fcfcfc",
	actionwarningPressed: "#e17a00",
	actionwarningDisabledlight: "#f2f2f8",
	actionwarningHover: "#e17a00",
	actionwarningDefault: "#e17a00",
	actionsuccessText: "#fcfcfc",
	actionsuccessPressed: "#00866f",
	actionsuccessDisabledlight: "#f2f2f8",
	actionsuccessHover: "#00866f",
	actionsuccessDefault: "#00866f",
	actioncriticalText: "#fcfcfc",
	actioncriticalPressed: "#d43b3b",
	actioncriticalDisabledlight: "#f2f2f8",
	actioncriticalHover: "#d43b3b",
	actioncriticalDefault: "#d43b3b",
	actionlegacysecondaryText: "#14142b",
	actionlegacysecondaryPressed: "#f2f2f8",
	actionlegacysecondaryDisabledlight: "#f2f2f8",
	actionlegacysecondaryHover: "#eff0f6",
	actionlegacysecondaryDefault: "#f2f2f8",
	actionlegacyprimaryText: "#fcfcfc",
	actionlegacyprimaryPressed: "#4abb10",
	actionlegacyprimaryDisabledlight: "#f2f2f8",
	actionlegacyprimaryHover: "#5ec031",
	actionlegacyprimaryDefault: "#4abb10",
	actionsecondaryText: "#14142b",
	actionsecondaryPressed: "#f2f2f8",
	actionsecondaryDisabledlight: "#f2f2f8",
	actionsecondaryHover: "#eff0f6",
	actionsecondaryDefault: "#f2f2f8",
	actionprimaryText: "#fcfcfc",
	actionprimaryPressed: "#12aaf6",
	actionprimaryDisabledlight: "#f2f2f8",
	actionprimaryHover: "#2eb2f7",
	actionprimaryDefault: "#12aaf6",
	focusDefault: "#accafa",
	strokeWarninglarge: "#ffcc80",
	strokeAttentionlarge: "#ff5c5c",
	strokeAttention: "#ff5c5c",
	strokeValidationlarge: "#00a980",
	strokePrimarysmall: "#0a70a3",
	strokeFocus: "#accafa",
	strokeInformationlight: "#e4f1ff",
	strokeInformationmedium: "#accafa",
	strokeInformation: "#12aaf6",
	strokeInformationdark: "#0a70a3",
	strokeWarningmedium: "#fdf3e0",
	strokeWarning: "#ffcc80",
	strokeWarningdark: "#e17a00",
	strokeSuccesslight: "#e4f5ef",
	strokeSuccessmedium: "#88e3d3",
	strokeSuccess: "#00a980",
	strokeSuccessdark: "#00866f",
	strokeCriticallight: "#ffeded",
	strokeCriticalmedium: "#ffb2b2",
	strokeCritical: "#ff5c5c",
	strokeCriticaldark: "#d43b3b",
	strokeDiscountlight: "#ffeded",
	strokeDiscountmedium: "#ffb2b2",
	strokeDiscount: "#ff5c5c",
	strokeDiscountdark: "#d43b3b",
	strokePrimary: "#12aaf6",
	strokePrimarydark: "#0a70a3",
	strokeNone: "rgba(255, 255, 255, 0)",
	strokeLightsubtle: "#f2f2f8",
	strokeLightneutral: "#d9dbe9",
	strokeDarkneutral: "#a0a3bd",
	strokeDisabledlight: "#d9dbe9",
	strokeLight: "#fcfcfc",
	strokeWhite: "#ffffff",
	strokeDark: "#14142b",
	interactiveDisabledlight: "#d9dbe9",
	interactiveSelected: "#14142b",
	interactiveHover: "#4e4b66",
	interactiveDefault: "#6e7191",
	iconGooglereview: "#f4b400",
	iconMove: "#00866f",
	iconRemovetag: "#8447ee",
	iconAddtag: "#12aaf6",
	iconPrimarysmall: "#0a70a3",
	iconInformationlarge: "#ffcc80",
	iconInformationsmall: "#e17a00",
	iconValidationlarge: "#00a980",
	iconValidationsmall: "#00866f",
	iconAttentionlarge: "#ff5c5c",
	iconAttentionsmall: "#d43b3b",
	iconTrustpilot5star: "#00b67a",
	iconTrustpilot4star: "#73cf11",
	iconTrustpilot3star: "#ffce00",
	iconTrustpilot2star: "#ff8622",
	iconTrustpilot1star: "#ff3722",
	iconTrustpilot0star: "#d9dbe9",
	iconTripadvisor: "#00aa6c",
	iconInformation: "#12aaf6",
	iconInformationdark: "#0a70a3",
	iconWarning: "#ffcc80",
	iconWarningdark: "#e17a00",
	iconSuccess: "#00a980",
	iconSuccessdark: "#00866f",
	iconCritical: "#ff5c5c",
	iconCriticaldark: "#d43b3b",
	iconDiscount: "#ff5c5c",
	iconDiscountdark: "#d43b3b",
	iconPrimary: "#12aaf6",
	iconPrimarydark: "#0a70a3",
	iconPressed: "#14142b",
	iconHovered: "#4e4b66",
	iconDimmedheavy: "#4e4b66",
	iconDimmedmedium: "#6e7191",
	iconDisabled: "#a0a3bd",
	iconDisabledlight: "#d9dbe9",
	iconLight: "#fcfcfc",
	iconDefault: "#14142b",
	textInformationlarge: "#ffcc80",
	textInformationsmall: "#e17a00",
	textValidationlarge: "#00a980",
	textValidationsmall: "#00866f",
	textAttentionlarge: "#ff5c5c",
	textAttentionsmall: "#d43b3b",
	textPrimarysmall: "#0a70a3",
	textInformation: "#12aaf6",
	textInformationdark: "#0a70a3",
	textWarning: "#ffcc80",
	textWarningdark: "#e17a00",
	textSuccess: "#00a980",
	textSuccessdark: "#00866f",
	textCritical: "#ff5c5c",
	textCriticaldark: "#d43b3b",
	textDiscount: "#ff5c5c",
	textDiscountdark: "#d43b3b",
	textLegacydark: "#41a50e",
	textLegacy: "#4abb10",
	textPrimary: "#12aaf6",
	textPrimarydark: "#0a70a3",
	textDimmedheavy: "#4e4b66",
	textDimmedmedium: "#6e7191",
	textDisabled: "#a0a3bd",
	textDisabledlight: "#d9dbe9",
	textLight: "#fcfcfc",
	textDefault: "#14142b",
	backgroundValidationlight: "#e4f5ef",
	backgroundValidationmedium: "#88e3d3",
	backgroundValidation: "#00a980",
	backgroundValidationdark: "#00866f",
	backgroundAttentionlight: "#ffeded",
	backgroundAttentionmedium: "#ffb2b2",
	backgroundAttention: "#ff5c5c",
	backgroundAttentiondark: "#d43b3b",
	backgroundMove: "#e4f5ef",
	backgroundRemovetag: "#ebe5f9",
	backgroundAddtag: "#e4f1ff",
	backgroundSteel: "#000000",
	backgroundInformationlight: "#e4f1ff",
	backgroundInformationmedium: "#accafa",
	backgroundInformation: "#12aaf6",
	backgroundInformationdark: "#0a70a3",
	backgroundWarninglight: "#fff9ed",
	backgroundWarningmedium: "#fdf3e0",
	backgroundWarning: "#ffcc80",
	backgroundWarningdark: "#e17a00",
	backgroundSuccesslight: "#e4f5ef",
	backgroundSuccessmedium: "#88e3d3",
	backgroundSuccess: "#00a980",
	backgroundSuccessdark: "#00866f",
	backgroundCriticallight: "#ffeded",
	backgroundCriticalmedium: "#ffb2b2",
	backgroundCritical: "#ff5c5c",
	backgroundCriticaldark: "#d43b3b",
	backgroundDiscountlight: "#ffeded",
	backgroundDiscountmedium: "#ffb2b2",
	backgroundDiscount: "#ff5c5c",
	backgroundDiscountdark: "#d43b3b",
	backgroundPrimarylight: "#e4f1ff",
	backgroundPrimarymedium: "#accafa",
	backgroundPrimary: "#12aaf6",
	backgroundLight: "#fbfbfb",
	backgroundLightsubtle: "#f2f2f8",
	backgroundLightneutral: "#d9dbe9",
	backgroundDarkneutral: "#a0a3bd",
	backgroundDarksubtle: "#6e7191",
	backgroundDark: "#14142b",
	backgroundNone: "rgba(255, 255, 255, 0)",
	backgroundWhite: "#ffffff",
	backgroundBlack: "#000000",
	baseSuccess: "#00a980",
	baseCritical: "#ff5c5c",
	baseWarning: "#ffcc80",
	baseInformation: "#12aaf6",
	baseValidation: "#00a980",
	baseAttention: "#ff5c5c",
	baseInteraction: "#14142b",
	baseLight: "#fbfbfb",
	baseDark: "#14142b",
	baseSecondary: "#cde3f8",
	basePrimary: "#12aaf6",
	baseYellowlight: "#fff2d5",
	baseYellow: "#f9c050",
} as const;

export type LoveholidaysOldColors = Record<
	keyof typeof loveholidaysOldColors,
	string
>;
