export const badgeVariants = [
  "Critical",
  "CriticalInverted",
  "Informative",
  "InformativeInverted",
  "Neutral",
  "NeutralInverted",
  "NeutralInvertedTransparent",
  "NeutralTransparent",
  "PrimaryBlue",
  "PrimaryBlueInverted",
  "PrimaryYellow",
  "PrimaryYellowInverted",
  "Success",
  "SuccessInverted",
  "Tag",
  "Warning",
  "WarningInverted"
] as const;